import React, { useState, useEffect, useContext } from "react";

import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";

import { notificationApi } from "~/api/dashboard/notificationApi";

import { UserContext } from "~/components/child/UserContext";
import { Loading } from "~/components/child/ui/Loading";
import Modal from "~/components/child/ui/Modal";
import Button from "~/components/child/ui/Button";
import CreateNew from "../CreateNew";
import { Item } from "./Item";

import { useKeySubmission } from "~/utils/hooks";
import { changeWordFormatToTitleCase } from "~/utils/changeWordFormatToTitleCase";

import ArrowLeftSvg from "~/assets/icon/svgJsx/ArrowLeftSvg";
import ArrowRightSvg from "~/assets/icon/svgJsx/ArrowRightSvg";

const List = () => {
  const [popUpIsOpen, setPopUpIsOpen] = useState({
    createNew: false,
    confirm: undefined,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [notificationType, setNotificationType] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const [listItems, setListItems] = useState([]);
  const [showMoreActionsTooltipId, setShowMoreActionsTooltipId] = useState(null);
  const { user } = useContext(UserContext);
  const permissions = user?.role[0]?.permissions;
  const notificationFilters =
    user?.role[0]?.name !== "user"
      ? ["all", "general", "heatmap", "technical", "setup"]
      : ["all", "general", "technical"];

  const fetchNotificationList = async () => {
    setIsLoading(true);
    try {
      const result = await notificationApi.list(notificationType, currentPage);

      setListItems(result.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching pairs data:", error);
    }
  };

  const changeNotificationStatus = async (id) => {
    setIsLoading(true);
    try {
      const result = await notificationApi.activation(id);
      if (result.data) {
        setPopUpIsOpen({
          ...popUpIsOpen,
          confirm: undefined,
        });
        fetchNotificationList();
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching pairs data:", error);
    }
  };

  useEffect(() => {
    if (currentPage && notificationType && permissions?.notifications_list)
      fetchNotificationList();
  }, [currentPage, notificationType, permissions]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useKeySubmission(
    () => {
      if (popUpIsOpen.confirm) changeNotificationStatus(popUpIsOpen.confirm);
    },
    popUpIsOpen.confirm ? () => isLoading : undefined,
    (event) => event.key === "Enter",
    []
  );

  return (
    <>
      {popUpIsOpen.createNew && (
        <Modal className={{ container: "py-3 px-[2px] w-[27%] max-w-none" }}>
          <CreateNew
            onSuccess={fetchNotificationList}
            setPopUpIsOpen={(value) =>
              setPopUpIsOpen({ ...popUpIsOpen, createNew: value })
            }
          />
        </Modal>
      )}
      {popUpIsOpen.confirm && (
        <Modal
          className={{
            container: "py-3 px-0 gap-6 flex items-center w-[18%] max-w-none",
          }}
        >
          <p className=" font-roboto text-base text-oil-60">
            You are changing the status of notification.
            <br />
            Are you sure?
          </p>
          <div className="flex items-center justify-center gap-2">
            <Button
              onClick={() => setPopUpIsOpen({ ...popUpIsOpen, confirm: undefined })}
              variant="outline"
            >
              cancel
            </Button>
            <Button
              onClick={() => changeNotificationStatus(popUpIsOpen.confirm)}
              variant="primary"
              isLoading={isLoading}
              disabled={isLoading}
              className="px-4"
            >
              Yes, it's ok
            </Button>
          </div>
        </Modal>
      )}

      <div className="main__router__container">
        <div className="w-[93%] mt-12 overflow-hidden flex flex-col mb-0 mx-auto ">
          {!isLoading && listItems?.data?.length > 0 ? (
            <>
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-4 font-roboto">
                  {notificationFilters.map((item) => (
                    <button
                      className={`text-base text-sky-70 ${
                        notificationType === item ? "font-robotoMed" : ""
                      }`}
                      onClick={() => setNotificationType(item)}
                    >
                      {changeWordFormatToTitleCase(item)}
                    </button>
                  ))}
                </div>

                {permissions?.new_notification && (
                  <button
                    className=" bg-sky-80 rounded-3xl text-base text-sm cursor-pointer font-robotoMed py-1.5 px-3"
                    onClick={() => setPopUpIsOpen({ ...popUpIsOpen, createNew: true })}
                  >
                    + New notification
                  </button>
                )}
              </div>
              <div className="mt-4">
                <div className="mb-4 flex flex-col gap-4 overflow-y-auto pr-1 h-[74vh] scrollbar-style scrollbar-style-thumb">
                  {listItems &&
                    listItems?.data?.map((item) => {
                      const epValues = item.points.some((point) => "ep" in point)
                        ? item.points
                            .find((point) => "ep" in point)
                            ?.ep.map((epItem) => ({
                              amount: epItem.amount,
                              is_goes_over: epItem.is_goes_over,
                            }))
                        : null;

                      const slValues = item.points.some((point) => "sl" in point)
                        ? item.points
                            .find((point) => "sl" in point)
                            ?.sl.map((slItem) => ({
                              amount: slItem.amount,
                              is_goes_over: slItem.is_goes_over,
                            }))
                        : null;

                      const tpValues = item.points.some((point) => "tp" in point)
                        ? item.points
                            .find((point) => "tp" in point)
                            ?.tp.map((tpItem) => ({
                              amount: tpItem.amount,
                              is_goes_over: tpItem.is_goes_over,
                              rr: tpItem.rr,
                            }))
                        : null;

                      return (
                        <Item
                          key={item.uuid}
                          item={item}
                          slValues={slValues}
                          tpValues={tpValues}
                          epValues={epValues}
                          showMoreActionsTooltipId={showMoreActionsTooltipId}
                          onMoreActionsClick={(uuid) => {
                            setShowMoreActionsTooltipId((prevId) =>
                              prevId === uuid ? null : uuid
                            );
                          }}
                          setPopUpIsOpen={() =>
                            setPopUpIsOpen({
                              ...popUpIsOpen,
                              confirm: item.uuid,
                            })
                          }
                        />
                      );
                    })}
                </div>

                <div className="pagination-one">
                  <Pagination
                    current={currentPage}
                    total={listItems?.meta?.total}
                    pageSize={10}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                    prevIcon={<ArrowLeftSvg />}
                    nextIcon={<ArrowRightSvg />}
                  />
                </div>
              </div>
            </>
          ) : !isLoading ? (
            <div className="flex gap-3 w-full items-center justify-center flex-col m-auto">
              <h4 className=" text-lg font-light text-sky-80">
                You don’t have any notification yet!
              </h4>
              <button
                className=" bg-sky-80 text-base py-1.5 px-3 rounded-3xl font-robotoMed cursor-pointer text-sm"
                onClick={() => setPopUpIsOpen({ ...popUpIsOpen, createNew: true })}
              >
                + New notification
              </button>
            </div>
          ) : (
            <Loading size="large" />
          )}
        </div>
      </div>
    </>
  );
};

export default List;

import React, { useState } from "react";

import NotificationPointsSvg from "~/components/child/NotificationPointsSvg";

import { getStatusColor } from "~/components/pages/heatMap/notification/list/Item";

import { changeWordFormatToTitleCase } from "~/utils/changeWordFormatToTitleCase";

import NotificationSvg from "~/assets/icon/svgJsx/NotificationSvg";
import arrowTop from "~/assets/icon/arrows/arrow-top-medium.svg";
import arrowBottom from "~/assets/icon/arrows/arrow-bottom-medium.svg";

const PointItem = ({ title, amount, status, riskToReward, pointItemKey }) => {
  return (
    <div key={pointItemKey} className="alerts__list__item__points__item">
      <span
        className={`alerts__list__item__points__item__title alerts__list__item__points__item__title-${title.toLowerCase()} `}
      >
        {title}
      </span>
      <span className="alerts__list__item__points__item__amount">{amount}</span>
      <span className="alerts__list__item__points__item__status">
        <NotificationPointsSvg status={status} />
      </span>
      {riskToReward && <div className="btn-ocean-cstm">RR {riskToReward}</div>}
    </div>
  );
};

const AlertItem = ({ alert }) => {
  const [showDescription, setShowDescription] = useState(false);
  const tpValues = () => {
    for (let i = 0; i < alert?.points?.length; i++) {
      if ("tp" in alert?.points[i]) {
        return alert?.points[i].tp;
      }
    }
    return null;
  };

  const status = alert.is_disabled
    ? "disable"
    : alert.is_complete
    ? "complete"
    : alert.is_active
    ? "active"
    : "inactive";

  return (
    <div className="alerts__list__item">
      <div className="alerts__list__item__header">
        <div className="alerts__list__item__header__date">
          <p className="alerts__list__item__header__date-validation">
            Validation date{" "}
            <span className={getStatusColor(status)}>
              {alert?.validation_date}
            </span>
          </p>
          <p className="alerts__list__item__header__date-creation">
            Creation date <span>{alert?.created_at}</span>
          </p>
        </div>
        <div
          className={`alerts__list__item__header__status ${getStatusColor(
            status
          )}`}
        >
          <i>
            <NotificationSvg
              color={
                status === "disable"
                  ? "#FF9500"
                  : status === "active"
                  ? "#349D57"
                  : "#df2040"
              }
            />
          </i>
          <span>{changeWordFormatToTitleCase(status)}</span>
        </div>
      </div>
      <div className="alerts__list__item__points">
        {alert?.points?.map((points) =>
          Object.values(points).map((item, index) => {
            if (item.length > 1) {
              if (showDescription)
                return item.map((tpItem, index) => (
                  <PointItem
                    title={tpItem?.point_type}
                    amount={tpItem?.amount}
                    status={tpItem?.is_goes_over}
                    riskToReward={tpItem?.rr}
                    pointItemKey={index}
                  />
                ));
              else
                return (
                  <PointItem
                    pointItemKey={index}
                    title={item[0].point_type}
                    amount="multi numbs"
                  />
                );
            }
            return (
              <PointItem
                title={item[0].point_type}
                amount={item[0].amount}
                status={item[0].is_goes_over}
                riskToReward={item[0].rr}
                pointItemKey={index}
              />
            );
          })
        )}
      </div>
      {(alert?.description?.length > 0 || tpValues()?.length > 1) && (
        <div className="alerts__list__item__description">
          {showDescription ? (
            <>
              <p>{alert?.description}</p>
              <button
                onClick={() => {
                  setShowDescription(false);
                }}
              >
                Hide description
                <img src={arrowTop} alt="arrow top" />
              </button>
            </>
          ) : (
            <button onClick={() => setShowDescription(true)}>
              Show description
              <img src={arrowBottom} alt="arrow bottom" />
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default AlertItem;

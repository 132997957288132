import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { widget } from "~/charting_library";
import { WidgetCreatorClass } from "../../../utils/classes/widgetCreatorClass";
import { createChartConfig } from "../../../config/createChartConfig";
import { getPairDataConfig } from "../../../utils/helpers/tools/getPairDataConfig";
import { convertDateToUnix, processChartData } from "./helper";

import "../../index.css";

export const TVChartContainer = React.memo(
  ({
    pairDetail,
    interval,
    fundChartData,
    setFundChartData,
    entriesCardListData,
    theme,
  }) => {
    const chartContainerRef = useRef();
    const createWidgetRef = useRef(null);
    const tvWidgetRef = useRef(null);

    const [isChartReady, setIsChartReady] = useState(false);
    const [verticalLineWidgetIds, setVerticalLineWidgetIds] = useState([]);

    const { baseChartConfig, specificConfigs } = createChartConfig("fundamental");
    const { pair, dataFeed: datafeedUrl } = getPairDataConfig(pairDetail, "camelCase");
    const finalChartData = processChartData(fundChartData, "D");

    useEffect(() => {
      const createWidget = createWidgetRef.current;
      const tvWidget = tvWidgetRef.current;
      if (isChartReady && createWidget && tvWidget) {
        verticalLineWidgetIds.forEach((id) => {
          tvWidget.activeChart().removeEntity(id, {
            disableUndo: true,
          });
        });

        const newVerticalLineWidgetIds = entriesCardListData.map((cardData) => {
          const date = convertDateToUnix(cardData);
          const result = createWidget.verticalLine(date, "fundamentalDatePoint");
          return result.shapeId;
        });

        setVerticalLineWidgetIds(newVerticalLineWidgetIds);
      }
    }, [entriesCardListData, isChartReady]);

    useEffect(() => {
      const widgetOptions = {
        ...baseChartConfig?.defaultProps,
        container: chartContainerRef.current,
        datafeed: baseChartConfig?.createDatafeed(datafeedUrl),
        disabled_features: specificConfigs?.create?.disabledFeatures,
        enabled_features: specificConfigs?.create?.enabledFeatures,
        theme: theme,
        symbol: pair,
        interval: interval,
        saved_data: finalChartData,
      };

      const tvWidget = new widget(widgetOptions);
      const createWidget = new WidgetCreatorClass(tvWidget);
      tvWidgetRef.current = tvWidget;
      createWidgetRef.current = createWidget;

      tvWidget.onChartReady(() => {
        setIsChartReady(true);
        tvWidget.headerReady().then(() => {
          tvWidget.save((data) => {
            setFundChartData(data);
          });
          tvWidget.subscribe("undo_redo_state_changed", () => {
            tvWidget.save((data) => {
              setFundChartData(data);
            });
          });
        });
      });

      return () => {
        if (tvWidgetRef.current) {
          tvWidgetRef.current.remove();
          tvWidgetRef.current = null;
          createWidgetRef.current = null;
          setIsChartReady(false);
        }
      };
    }, [theme]);

    return <div ref={chartContainerRef} className={"TVChartContainer"} />;
  }
);

TVChartContainer.propTypes = {
  pairDetail: PropTypes.object.isRequired,
  interval: PropTypes.string.isRequired,
  fundChartData: PropTypes.object.isRequired,
  setFundChartData: PropTypes.func.isRequired,
  entriesCardListData: PropTypes.array.isRequired,
  theme: PropTypes.string.isRequired,
};

import { hasCharacter } from "../../utils/helpers/tools/hasCharacter";

export const priceTypeDetermine = (price) => {
  let state = {};

  if (hasCharacter(price, "-")) {
    let newPrice = price.split("-");
    state = {
      priceRange: newPrice,
      type: "range",
    };
  }

  if (!hasCharacter(price, "-")) {
    state = {
      priceRange: price,
      type: "single",
    };
  }

  return state;
};

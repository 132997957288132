import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Button from "~/components/child/ui/Button";
import { Loading } from "~/components/child/ui/Loading";
import Breadcrumb from "~/components/child/Breadcrumb";
import { TVChartContainer as TechnicalTVChartContainer } from "~/components/child/tradingViewMainChart/tvChartContainer/technical/create/TVChartContainer";
import { TVChartContainer as FundamentalTVChartContainer } from "~/components/child/tradingViewMainChart/tvChartContainer/fundamental/create/TVChartContainer";
import { useSanitizedMainChartData } from "~/components/child/tradingViewMainChart/utils/hooks/useSanitizedMainChartData";
import { chartSize } from "~/components/child/tradingViewMainChart/utils/helpers/tools/createChartStyles";
import DataFailureAlert from "~/components/child/tradingViewMainChart/ui/DataFailureAlert";
import { NotificationModal } from "~/components/child/notification/ReceiveNotification";
import Modal from "~/components/child/ui/Modal";
import NewAnalysis from "./NewAnalysis";
import TechnicalDetails from "./technical/details/Details";
import FundamentalDetails from "./fundamental/Details";
import TechnicalPreview from "./technical/Preview";
import FundamentalPreview from "./fundamental/Preview";
import OnChainCreation from "./onChain";

import { changeWordFormatToTitleCase } from "~/utils/changeWordFormatToTitleCase";
import { onChangeCheckboxes } from "~/utils/onChangeCheckboxes";
import { useMainChartDetails, useKeySubmission } from "~/utils/hooks";
import { createOrEditAnalysis } from "./helper";
import { fetchTechnicalDetails } from "../view/technical/helper";
import { transformedAnalysisData } from "~/utils/getTransformedData";
import { apiConfig } from "~/utils/constants";

import analysisImg from "~/assets/img/analysisImg.svg";

const CreateNew = ({ permissions, user }) => {
  const initialAnalysisValues = {
    new: {
      pair: {
        value: "BTCUSDT",
        key: "BTCUSDT",
        image: [
          "https://s2.coinmarketcap.com/static/img/coins/64x64/1.png",
          "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png",
        ],
        baseSymbol: "BTC",
        quoteSymbol: "USDT",
        exchange: "binance",
      },
      analysisType: "technical",
      positionType: "long",
    },
    technical: {
      type: "setup",
      timeFrame: {
        key: 4,
        value: "15 Minutes",
      },
      points: {
        ep: [{ value: "", desc: "" }],
        tp: [{ value: "", desc: "" }],
        sl: [{ value: "", desc: "" }],
      },
      notificationUsers: [],
      images: [],
      description: "",
      chart: null,
    },
    fundamental: {
      entries: [],
      images: [],
      description: "",
      chart: null,
    },
    onChain: {
      title: "",
      description: "",
      images: [],
    },
  };
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const [step, setStep] = useState("newAnalysis");
  const [analysisDetails, setAnalysisDetails] = useState();
  const [analysisValues, setAnalysisValues] = useState({
    ...initialAnalysisValues,
  });

  const [notification, setNotification] = useState({
    modal: false,
    switch: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [dataHasChanged, setDataHasChanged] = useState(false);

  const {
    chartData,
    theme: mainChartTheme,
    isLoading: mainChartIsLoading,
  } = useMainChartDetails();
  const sanitizedMainChartData = useSanitizedMainChartData(chartData);

  const isEditMode = step === "details" && id ? true : false;
  const allPointsHaveValues = Object.values(analysisValues.technical.points).every(
    (arr) => {
      return arr.some((item) => item.value.trim() !== "");
    }
  );
  const hasValue = Object.values(analysisValues.technical.points).some((pointGroup) => {
    return pointGroup.some((point) => point.value !== "");
  });

  const [blurPointInputTrigger, setBlurPointInputTrigger] = useState(0);

  const handleOnBlurPointInput = () => {
    setBlurPointInputTrigger((prev) => prev + 1);
  };

  const technicalChartProps = useMemo(
    () => ({
      tradeBoundariesPoints: analysisValues?.technical.points,
      pairDetail: analysisValues?.new?.pair,
      initInterval: analysisValues?.technical?.timeFrame?.value,
      baseDateType: "create",
      theme: mainChartTheme,
      techChartData: analysisValues.technical.chart,
      setTechChartData: (chartData) =>
        setAnalysisValues((prevState) => ({
          ...prevState,
          technical: {
            ...prevState.technical,
            chart: chartData,
          },
        })),
      setDataHasChanged,
      blurPointInputTrigger,
      step,
    }),
    [
      mainChartTheme,
      analysisValues.technical.chart,
      analysisValues?.new?.pair,
      analysisValues?.technical?.points,
      analysisValues?.technical?.timeFrame?.value,
      blurPointInputTrigger,
      step,
    ]
  );

  const fundamentalChartProps = useMemo(
    () => ({
      pairDetail: analysisValues.new.pair,
      interval: "D",
      entriesCardListData: analysisValues.fundamental?.entries,
      theme: mainChartTheme,
      fundChartData: analysisValues.fundamental.chart,
      setFundChartData: (chartData) =>
        setAnalysisValues((prevState) => ({
          ...prevState,
          fundamental: {
            ...prevState.fundamental,
            chart: chartData,
          },
        })),
    }),
    [step, mainChartTheme, analysisValues.fundamental?.entries]
  );

  useEffect(() => {
    // just once
    if (sanitizedMainChartData) {
      if (!analysisValues.technical.chart)
        setAnalysisValues({
          ...analysisValues,
          technical: {
            ...analysisValues.technical,
            chart: sanitizedMainChartData,
          },
        });
      if (!analysisValues.fundamental.chart)
        setAnalysisValues({
          ...analysisValues,
          fundamental: {
            ...analysisValues.fundamental,
            chart: sanitizedMainChartData,
          },
        });
    }
  }, [
    sanitizedMainChartData,
    analysisValues.fundamental.chart,
    analysisValues.technical.chart,
  ]);

  useEffect(() => {
    setErrorMessage(undefined);
  }, [analysisValues]);

  useEffect(() => {
    if (id) {
      fetchTechnicalDetails(id, setAnalysisDetails, setIsLoading);
      setStep("details");
    } else {
      setStep("newAnalysis");
      setAnalysisValues({
        ...initialAnalysisValues,
      });
    }
  }, [id]);

  useEffect(() => {
    const getPoints = (title) => {
      return analysisDetails?.data?.details
        ?.filter((detail) => detail.point_type === title)
        ?.map((item) => ({
          value: `${item.base_amount}${item.max_amount > 0 ? "-" + item.max_amount : ""}`,
          desc: item.point_description,
          rr: item.rr,
        }));
    };
    if (analysisDetails) {
      setAnalysisValues({
        ...analysisValues,
        new: {
          ...analysisValues.new,
          pair: {
            value: analysisDetails?.data?.analysis?.pair?.pair,
            key: analysisDetails?.data?.analysis.pair.key,
            image: analysisDetails?.data?.analysis.pair.image,
            baseSymbol: analysisDetails?.data?.analysis.pair.base_symbol,
            quoteSymbol: analysisDetails?.data?.analysis.pair.quote_symbol,
            exchange: analysisDetails?.data?.analysis.pair.exchange,
          },
          positionType: analysisDetails?.data?.analysis?.position_type?.toLowerCase(),
        },
        technical: {
          ...analysisValues.technical,
          type: analysisDetails?.data?.technical_type
            ? analysisDetails?.data?.technical_type[0]?.technical_type
            : "setup",
          timeFrame: {
            key: analysisDetails?.data?.analysis?.time_frame?.id,
            value: analysisDetails?.data?.analysis?.time_frame?.time_frame,
          },
          points: {
            ep: getPoints("EP")?.length > 0 ? getPoints("EP") : [{ value: "", desc: "" }],
            tp:
              getPoints("TP")?.length > 0
                ? getPoints("TP")
                : [
                    {
                      value: "",
                      desc: "",
                      rr: "",
                    },
                  ],
            sl: getPoints("SL")?.length > 0 ? getPoints("SL") : [{ value: "", desc: "" }],
          },
          description: analysisDetails?.data?.analysis.description || "",
          chart: JSON.parse(JSON.parse(analysisDetails?.data?.chart_data)),
          images:
            analysisDetails?.data?.pictures?.length > 0
              ? analysisDetails?.data?.pictures?.map((pic) => ({
                  id: pic.id,
                  src: apiConfig.BASE_API_URL + "/trade/" + pic.picture,
                  active: pic.is_primary ? 1 : 0,
                  name: pic.picture.split("/")[1],
                }))
              : [],
        },
      });
    }
  }, [analysisDetails]);

  const isButtonDisable = () => {
    return (
      (step === "details" &&
        ((analysisValues?.new?.analysisType === "technical"
          ? (analysisValues.technical.type === "setup"
              ? !allPointsHaveValues
              : !hasValue && analysisValues.technical.description?.length === 0) ||
            analysisValues?.technical?.images?.length > 5
          : analysisValues.fundamental.entries?.length === 0 ||
            analysisValues?.fundamental.images?.length > 5) ||
          (!dataHasChanged && isEditMode))) ||
      isLoading ||
      notification.modal
    );
  };

  const onSubmit = () => {
    if (step === "newAnalysis") {
      if (analysisValues?.new?.analysisType === "on-chain") setStep("onChainCreation");
      else setStep("details");
    } else if (isEditMode)
      createOrEditAnalysis(
        id,
        analysisValues?.new?.analysisType,
        setIsLoading,
        analysisValues,
        (uuid) => {
          navigate(`/${analysisValues?.new?.analysisType}-view/${uuid}`);
        },
        setErrorMessage
      );
    else if (step === "details") setStep("preview");
    else if (step === "preview")
      createOrEditAnalysis(
        id,
        analysisValues?.new?.analysisType,
        setIsLoading,
        analysisValues,
        () => {
          navigate("/analysisList");
        },
        setErrorMessage,
        notification.switch,
        user.uuid
      );
  };

  useKeySubmission(onSubmit, isButtonDisable, (event) => event.key === "Enter", []);

  if (step !== "onChainCreation")
    return (
      <div className="main__router__container">
        <div className="bg-base h-[94vh] overflow-x-hidden overflow-y-auto w-[35%] scrollbar-style-thumb scrollbar-style">
          <aside className={`flex flex-col m-4 ${step === "newAnalysis" ? "mt-24" : ""}`}>
            {permissions?.new_analytic ? (
              <>
                {step === "newAnalysis" ? (
                  <NewAnalysis
                    isEditMode={isEditMode}
                    analysisValues={analysisValues}
                    setAnalysisValues={setAnalysisValues}
                    initialAnalysisValues={initialAnalysisValues}
                  />
                ) : (
                  <>
                    {!isEditMode ? (
                      <Breadcrumb
                        steps={[
                          {
                            label: "Analysis type",
                            active: 0,
                          },
                          {
                            label: "Details",
                            active: step === "details" ? 1 : 0,
                          },
                          {
                            label: "Preview",
                            active: step === "preview" ? 1 : 0,
                          },
                        ]}
                      />
                    ) : (
                      <h4 className="mt-8 mb-4 text-base text-sky-70">
                        Edit{" "}
                        {changeWordFormatToTitleCase(analysisValues?.new?.analysisType)}{" "}
                        {changeWordFormatToTitleCase(analysisValues?.technical.type)}
                      </h4>
                    )}

                    {step === "details" && (
                      <>
                        {analysisValues?.new?.analysisType === "technical" ? (
                          <>
                            <TechnicalDetails
                              analysisValues={analysisValues}
                              setAnalysisValues={setAnalysisValues}
                              isEditMode={isEditMode}
                              setDataHasChanged={(value) => setDataHasChanged(value)}
                              notification={notification}
                              setNotification={setNotification}
                              handleOnBlurPointInput={handleOnBlurPointInput}
                            />
                          </>
                        ) : (
                          analysisValues?.new?.analysisType === "fundamental" && (
                            <FundamentalDetails
                              analysisValues={analysisValues}
                              setAnalysisValues={setAnalysisValues}
                            />
                          )
                        )}
                      </>
                    )}
                    {step === "preview" && (
                      <>
                        {analysisValues?.new?.analysisType === "technical" ? (
                          <TechnicalPreview analysisValues={analysisValues} />
                        ) : (
                          analysisValues?.new?.analysisType === "fundamental" && (
                            <FundamentalPreview analysisValues={analysisValues} />
                          )
                        )}
                      </>
                    )}
                  </>
                )}

                <div className="flex my-16 justify-end gap-4">
                  {step !== "newAnalysis" ? (
                    <Button
                      variant="outline"
                      className="w-1/2"
                      onClick={() => {
                        if (isEditMode)
                          navigate(`/${analysisValues?.new?.analysisType}-view/${id}`);
                        else if (step === "details") setStep("newAnalysis");
                        else setStep("details");
                      }}
                      tabIndex={1}
                      disabled={isLoading}
                    >
                      {isEditMode ? "Cancel" : "Back"}
                    </Button>
                  ) : null}

                  <Button
                    variant="primary"
                    isLoading={isLoading}
                    className="w-1/2"
                    disabled={isButtonDisable()}
                    onClick={onSubmit}
                    tabIndex={0}
                  >
                    {isEditMode
                      ? "Save Changes"
                      : step === "preview"
                      ? id
                        ? "Edit"
                        : "Apply"
                      : "Next"}
                  </Button>
                </div>
                {errorMessage && Object.keys(errorMessage) && (
                  <div className="flex flex-col items-center mb-8 mt-2">
                    {Object.keys(errorMessage)?.map((key) => (
                      <p
                        key={key}
                        className="mt-1 font-roboto text-sm text-infrared-key text-center overflow-auto"
                      >
                        {errorMessage[key]}
                      </p>
                    ))}
                  </div>
                )}
              </>
            ) : (
              permissions !== undefined && (
                <p className="my-[50%] mx-auto text-infrared-key">
                  You don't have permission to add new analysis.
                </p>
              )
            )}
          </aside>
        </div>
        <>
          {step !== "newAnalysis" ? (
            <div className={`${chartSize?.mode1}`}>
              {mainChartTheme && !mainChartIsLoading ? (
                <>
                  {analysisValues?.new?.analysisType === "technical" &&
                  analysisValues.technical.chart ? (
                    <TechnicalTVChartContainer {...technicalChartProps} />
                  ) : (
                    analysisValues?.new?.analysisType === "fundamental" &&
                    analysisValues.fundamental.chart && (
                      <FundamentalTVChartContainer {...fundamentalChartProps} />
                    )
                  )}
                </>
              ) : !mainChartIsLoading && !mainChartTheme ? (
                <DataFailureAlert />
              ) : (
                <Loading />
              )}
            </div>
          ) : (
            <div className="flex flex-row items-center justify-center w-full">
              <img className=" w-4/5" src={analysisImg} alt="" />
            </div>
          )}
        </>
        {notification.modal && (
          <Modal className={{ container: "w-2/5 min-h-[31.25rem]" }}>
            <NotificationModal
              selectedUsers={[...analysisValues?.technical?.notificationUsers]}
              setShowNotificationModal={(value) =>
                setNotification({
                  ...notification,
                  modal: value,
                })
              }
              onSelectUser={(item) =>
                setAnalysisValues({
                  ...analysisValues,
                  technical: {
                    ...analysisValues.technical,
                    notificationUsers: onChangeCheckboxes(
                      analysisValues.technical.notificationUsers,
                      {
                        key: item.key,
                        value: item.value,
                      }
                    ),
                  },
                })
              }
            />
          </Modal>
        )}
      </div>
    );
  else
    return (
      <OnChainCreation
        analysisValues={analysisValues}
        setAnalysisValues={setAnalysisValues}
        onBack={() => setStep("newAnalysis")}
        onSubmit={() =>
          createOrEditAnalysis(
            undefined,
            analysisValues?.new?.analysisType,
            setIsLoading,
            analysisValues,
            () => {
              navigate("/analysisList");
            },
            setErrorMessage
          )
        }
        isLoading={isLoading}
        errorMessage={errorMessage}
      />
    );
};

export default CreateNew;

import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import { widget } from "~/charting_library";
import { createChartConfig } from "~/components/child/tradingViewMainChart/config/createChartConfig.js";
import { createChartTimeFrame } from "../../../utils/creators/createChartTimeFrame";
import { convertIntervalToTradingViewFormat } from "../../../utils/converters/convertTimeFrameToTradingViewFormat";
import { convertDateToUnixTime } from "../../../utils/converters/convertDateToUnixTime";
import { WidgetCreatorClass } from "../../../utils/classes/widgetCreatorClass";
import { plotVerticalLineAtCreationTime } from "../../../utils/helpers/plotting/plotVerticalLineAtCreationTime";
import { getPairDataConfig } from "../../../utils/helpers/tools/getPairDataConfig";
import { processChartData } from "./helper";

import "../../index.css";

export const TVChartContainer = React.memo(
  ({ pairDetail, initInterval, createdAt, initChartData, details, theme }) => {
    const chartContainerRef = useRef();
    const { baseChartConfig, specificConfigs } = createChartConfig("fundamental");

    const [interval, setInterval] = useState(convertIntervalToTradingViewFormat(initInterval));

    const { pair, dataFeed: datafeedUrl } = getPairDataConfig(pairDetail, "snake_case");
    const { timeFrom, timeTo } = createChartTimeFrame(interval, createdAt.split(" ")[0]);

    const finalChartData = processChartData(initChartData, interval, theme);

    useEffect(() => {
      const widgetOptions = {
        ...baseChartConfig?.defaultProps,
        container: chartContainerRef.current,
        datafeed: baseChartConfig?.createDatafeed(datafeedUrl),
        disabled_features: specificConfigs?.detail?.disabledFeatures,
        enabled_features: specificConfigs?.detail?.enabledFeatures,
        theme: theme,
        symbol: pair,
        interval: interval,
        saved_data: finalChartData,
        timeframe: { from: timeFrom, to: timeTo },
      };

      const tvWidget = new widget(widgetOptions);
      const createWidget = new WidgetCreatorClass(tvWidget);

      tvWidget.onChartReady(() => {
        tvWidget.headerReady().then(() => {
          if (details && details.length > 0) {
            for (const detail of details) {
              const initDate = detail?.analysis_date;
              const date = initDate;
              const dateToUnixTime = convertDateToUnixTime(1, date.split(" ")[0]);
              createWidget.verticalLine(dateToUnixTime, "fundamentalDatePoint");
            }
          }

          plotVerticalLineAtCreationTime(createdAt, createWidget);

          tvWidget
            .activeChart()
            .onIntervalChanged()
            .subscribe(null, (interval) => {
              setInterval(convertIntervalToTradingViewFormat(interval));
            });
        });
      });

      return () => {
        tvWidget.remove();
      };
    }, [finalChartData, interval, theme]);

    return <div ref={chartContainerRef} className={"TVChartContainer"} />;
  }
);

TVChartContainer.propTypes = {
  pairDetail: PropTypes.object.isRequired,
  initInterval: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  initChartData: PropTypes.string.isRequired,
  details: PropTypes.array.isRequired,
  theme: PropTypes.string.isRequired,
};

import { convertDateToUnixTime } from "../../../utils/converters/convertDateToUnixTime";
import { createWidgetLabelOverrides } from "../../../utils/creators/createWidgetLabelOverrides";
import { tvColors } from "../../../utils/helpers/tools/tvColors";
import { validateInput } from "../../../utils/helpers/tools/validateInput";


export const fundamentalWidgetPlotProcess = (fundamentalList, createWidget, showAnalyserName ) => {
    const functionName = fundamentalWidgetPlotProcess.name;
    validateInput(fundamentalList, 'fundamentalList',functionName)
    validateInput(createWidget, 'createWidget', functionName)
    validateInput(showAnalyserName, 'showAnalyserName', functionName)

    fundamentalList &&
    fundamentalList.forEach((fundDetail) => {
      fundDetail?.details &&
        fundDetail?.details.length > 0 &&
        fundDetail?.details.forEach((funDetai) => {
          let initDate = funDetai?.analysis_date.split(" ");
          let date = initDate[0];
          const dateToUnixTime = convertDateToUnixTime(1, date);
          createWidget.verticalLine(
            dateToUnixTime,
            "fundamentalDatePoint",
            showAnalyserName &&
              createWidgetLabelOverrides("verticalLineHeatMap", {
                text: fundDetail?.analysis?.user_name,
                textcolor: tvColors?.purple,
              })
          );
        });
    });
}

import React from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, registerables, TimeScale } from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import "chartjs-adapter-date-fns";
import { normalizeData } from "./normalizedData";
import { Cart } from "./";

ChartJS.register(...registerables, TimeScale, zoomPlugin);

const BandedChart = ({ chartData }) => {
  const data = {
    labels: chartData.map((item) => item[0]),
    datasets: [
      {
        label: "Upper Limit",
        data: chartData.map((item) => item[3]),
        borderColor: "rgba(87, 168, 255, 1)",
        backgroundColor: "rgba(204, 228, 255, 1)",
        borderWidth: 1,
        pointRadius: 0,
      },

      {
        label: "ma",
        data: chartData.map((item) => item[2]),
        borderColor: "#7F0DF1",
        backgroundColor: "#7F0DF1",
        borderWidth: 1,
        fill: false,
        pointRadius: 0,
      },

      {
        label: "Lower Limit",
        data: chartData.map((item) => item[1]),
        borderColor: "rgba(87, 168, 255, 1)",
        backgroundColor: "rgba(204, 228, 255, 1)",
        borderWidth: 1,
        fill: "-2",
        pointRadius: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    interaction: {
      mode: "index",
    },
    scales: {
      x: {
        type: "time",
        time: {
          unit: "hour",
          displayFormats: {
            hour: "MM/dd HH:mm",
          },
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 20,
        },
      },
      y: {
        min: 0,
        max: 1,
        grid: {
          drawBorder: true,
        },
      },
    },
    plugins: {
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
            mode: "x",
          },
          pinch: {
            enabled: true,
            mode: "x",
          },
          mode: "x",
        },
        pan: {
          enabled: true,
          mode: "x",
        },
      },
    },
  };

  return (
    <div className="relative w-full h-full">
      <Line className="!w-full !h-full" data={data} options={options} />
    </div>
  );
};

export const Charts = ({ charts, carts }) => {
  return (
    <div
      className={`${
        charts?.length > 2 ? "grid grid-cols-2 gap-4" : "flex flex-col gap-4"
      } w-full py-8 px-4 `}
    >
      {charts?.map((item, index) => {
        const key = Object.keys(item)[0];
        const array = Object.values(item[key])[0][0].map((item) => Object.values(item));
        const normalizedData = normalizeData(
          array.map((item) => [item[3], item[0], item[1], item[4]])
        );

        const cart = carts?.find(
          (cartItem) =>
            cartItem.pair.key === key &&
            item[key] &&
            cartItem.time_frame === Object.keys(item[key])[0]
        );

        return (
          <div
            className={` flex flex-col gap-5 bg-storm-light rounded-2xl p-5`}
            key={index}
          >
            <Cart
              pair={cart.pair}
              timeFrame={cart.time_frame}
              breakTime={cart.break_time}
              breakType={cart.break_type}
            />
            <BandedChart chartData={normalizedData} />
          </div>
        );
      })}
    </div>
  );
};

export default Charts;


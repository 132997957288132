export const trimTrailingCharacter = (str, char) => {
  if (str.slice(-1) === char || str.slice(-1) === " ") {
    while (str.slice(-1) === char || str.slice(-1) === " ") {
      str = str.slice(0, str.length - 1);
    }
  } else {
    return (str = str);
  }

  return str;
};

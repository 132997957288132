import React, { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import ChartSquareSvg from "../../assets/icon/svgJsx/ChartSquareSvg";
import SettingSvg from "../../assets/icon/svgJsx/SettingSvg";
import ArrowRightSvg from "../../assets/icon/svgJsx/ArrowRightSvg";
import StrongBoxSvg from "../../assets/icon/svgJsx/StrongBoxSvg";

export default function Tools({
  parentCategoryName,
  navBoxIsOpen,
  toolsIsOpen,
  setToolsIsOpen,
  breadcrumbItems,
  user,
}) {
  const clickOnItems = () => {
    if (!navBoxIsOpen) {
      setToolsIsOpen(false);
    }
  };

  const parentItemRef = useRef(null);
  const childListRefItem = useRef(null);
  const currentMainListOfNav = breadcrumbItems?.main === parentCategoryName;

  useEffect(() => {
    if (!navBoxIsOpen) {
      setToolsIsOpen(false);
    }
    if (navBoxIsOpen && currentMainListOfNav) {
      setToolsIsOpen(true);
    }
    const handleClickOutside = (event) => {
      if (!navBoxIsOpen) {
        if (
          parentItemRef.current &&
          childListRefItem.current &&
          !parentItemRef.current.contains(event.target) &&
          !childListRefItem.current.contains(event.target)
        ) {
          setToolsIsOpen(false);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [parentItemRef, childListRefItem, navBoxIsOpen]);

  return (
    <li
      className={`nav__list__item itemClose ${toolsIsOpen && "listOpen"} ${
        !navBoxIsOpen && currentMainListOfNav && "isActiveClose"
      }`}
    >
      <div
        ref={parentItemRef}
        onClick={() => setToolsIsOpen(!toolsIsOpen)}
        className="nav__list__item__box"
      >
        <div className="nav__list__item__box__title">
          <i className="nav__list__item__box__icon">
            <SettingSvg />
          </i>
          <span>{navBoxIsOpen && <div> {parentCategoryName} </div>}</span>
        </div>

        <div>
          <i
            className={`nav__list__item__box__arrow-icon ${
              toolsIsOpen ? "arrow-rotate-left" : ""
            }`}
          >
            {/* arrow-right */}
            <ArrowRightSvg />
          </i>
        </div>
      </div>
      <div
        className={`nav__list__item__sub-category ${!navBoxIsOpen && "sub-ctgy-mode-2"}`}
      >
        <ul ref={childListRefItem}>
          {!navBoxIsOpen && (
            <span className="nav__list__item__sub-category__title">
              {parentCategoryName}
            </span>
          )}

          {user?.role[0]?.permissions?.analytics_list && (
            <NavLink
              onClick={clickOnItems}
              to="/imbalances"
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <li>
                <i>
                  <ChartSquareSvg />
                </i>
                <span>Imbalances</span>
              </li>
            </NavLink>
          )}
          {/* {user?.role[0].name !== "user" && (
            <NavLink
              onClick={clickOnItems}
              className={({ isActive }) => (isActive ? "active" : "")}
              to="/vmc"
            >
              <li>
                <i>
                  <StrongBoxSvg />
                </i>
                <span>V/MC</span>
              </li>
            </NavLink>
          )} */}
        </ul>
      </div>
    </li>
  );
}

import React, { useState, useEffect } from "react";
import Pagination from "rc-pagination/lib/Pagination";

import { NavLink } from "react-router-dom";

import { setupApi } from "~/api/dashboard/setupApi";

import ArrowLeftSvg from "~/assets/icon/svgJsx/ArrowLeftSvg";
import ArrowRightSvg from "~/assets/icon/svgJsx/ArrowRightSvg";
import ArrowLineRightSvg from "~/assets/icon/svgJsx/ArrowLineRightSvg";
import filterSvg from "~/assets/icon/filter.svg";

import { Filter } from "~/components/child/Filter";
import { Loading } from "~/components/child/ui/Loading";

import { changeWordFormatToTitleCase } from "~/utils/changeWordFormatToTitleCase";
import { statusesTheme } from "~/utils/statusesTheme";
import { transformedFilterItems } from "~/utils/getTransformedData";
import { getState } from "./helper";

const List = ({ permissions }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [listItems, setListItems] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [filterItemsCount, setFilterItemsCount] = useState(0);
  const [appliedFilters, setAppliedFilters] = useState({
    pairs: [],
    date: { from: undefined, to: undefined },
    analyzers: [],
  });

  const fetchSetupList = async (page) => {
    setIsLoading(true);
    try {
      const result = await setupApi.list(page, transformedFilterItems(appliedFilters));

      setListItems(result.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching pairs data:", error);
    }
  };

  useEffect(() => {
    if (currentPage && permissions?.full_access) {
      fetchSetupList(currentPage);
    }
  }, [permissions]);

  useEffect(() => {
    if (!showFilter) {
      setFilterItemsCount(0);
      for (const key in appliedFilters) {
        if (Array.isArray(appliedFilters[key])) {
          setFilterItemsCount((prevFilterItemsCount) => {
            return (prevFilterItemsCount += appliedFilters[key].length);
          });
        } else {
          if (appliedFilters.date.from)
            setFilterItemsCount((prevFilterItemsCount) => {
              return prevFilterItemsCount + 1;
            });
          if (appliedFilters.date.to)
            setFilterItemsCount((prevFilterItemsCount) => {
              return prevFilterItemsCount + 1;
            });
        }
      }
    }
  }, [appliedFilters, showFilter]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchSetupList(page);
  };

  const pointColor = (point) => {
    if (point.includes("EP")) return "sky-key";
    else if (point.includes("TP")) return "leaf-key";
    return "infrared-key";
  };

  return (
    <div className="main__router__container">
      {showFilter && (
        <Filter
          type="setup"
          appliedFilters={appliedFilters}
          setAppliedFilters={setAppliedFilters}
          isOpen={showFilter}
          setIsOpen={setShowFilter}
          onSubmitFilters={() => {
            setCurrentPage(1);
            fetchSetupList(currentPage);
          }}
          totalResults={listItems?.meta?.total}
          fullAccess={permissions.full_access}
        />
      )}
      {permissions?.full_access ? (
        <div className="main__router__container__list-box-container">
          {permissions?.full_access && (
            <button onClick={() => setShowFilter(true)} className="filterBtn">
              <img src={filterSvg} alt="filter icon" />
              Filter
              {filterItemsCount > 0 && <span>{filterItemsCount} Items</span>}
              <ArrowLineRightSvg />
            </button>
          )}
          {!isLoading && listItems?.data?.length > 0 ? (
            <div>
              <div className="list-table-cstm-one scrollbar-style scrollbar-style-thumb">
                <table className="list-table-cstm-one__content-table">
                  <thead>
                    <tr>
                      <th>Row</th>
                      <th>Pair</th>
                      <th>Type</th>
                      <th>User</th>
                      <th>State</th>
                      <th>Status</th>
                      <th>Date</th>
                      <th>Detail</th>
                    </tr>
                  </thead>

                  <tbody>
                    {listItems?.data?.map((item, index) => (
                      <tr key={index}>
                        <td>{(currentPage - 1) * 10 + index + 1}</td>
                        <td>{item.pair.pair}</td>

                        <td className="list-table-cstm-one__content-table__flex">
                          <div
                            className={`list-table-cstm-one__content-table__position-type ${item?.type?.toLowerCase()}`}
                          >
                            {changeWordFormatToTitleCase(item.type)}
                          </div>
                        </td>

                        <td>
                          <div className="flex m-auto py-1.5 px-2.5 text-storm-50 bg-storm-20 rounded-3xl text-xs w-fit">
                            {changeWordFormatToTitleCase(item?.user?.first_name)}{" "}
                            {changeWordFormatToTitleCase(item?.user?.last_name)}
                          </div>
                        </td>
                        <td>
                          <div
                            className={`${statusesTheme(
                              getState(item.state)
                            )} py-1 px-2.5 m-auto flex w-fit rounded-3xl border text-sm`}
                          >
                            {item.state}
                          </div>
                        </td>
                        <td>
                          <div className="gap-1.5 m-auto flex items-center w-fit text-sm">
                            {item?.status ? (
                              <>
                                {item?.status?.higher_point !== null && (
                                  <>
                                    <span
                                      className={`text-${pointColor(
                                        item?.status.higher_point
                                      )}  font-robotoMed`}
                                    >
                                      {item?.status.higher_point}
                                    </span>{" "}
                                    {">"}
                                  </>
                                )}

                                <span className=" font-roboto text-black">
                                  {item?.status?.current_point}
                                </span>
                                {item?.status?.lower_point !== null && (
                                  <>
                                    {">"}
                                    <span
                                      className={`text-${pointColor(
                                        item?.status?.lower_point
                                      )}  font-robotoMed`}
                                    >
                                      {item?.status?.lower_point}
                                    </span>
                                  </>
                                )}
                              </>
                            ) : (
                              "-"
                            )}
                          </div>
                        </td>

                        <td className="list-table-cstm-one__content-table__date-time">
                          <div className="list-table-cstm-one__content-table__date-time__date">
                            {item.date?.split(" ")[0]}
                          </div>
                          <div className="list-table-cstm-one__content-table__date-time__time">
                            {item.date?.split(" ")[1]}
                          </div>
                        </td>

                        <td className="list-table-cstm-one__content-table__details-arrow">
                          <NavLink
                            to={`/setup?id=${item?.uuid}`}
                            className="flex justify-center items-center"
                          >
                            <ArrowRightSvg />
                          </NavLink>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {listItems?.meta?.total > 10 && (
                <div className="pagination-one">
                  <Pagination
                    current={currentPage}
                    total={listItems?.meta?.total}
                    pageSize={10}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                    prevIcon={<ArrowLeftSvg />}
                    nextIcon={<ArrowRightSvg />}
                  />
                </div>
              )}
            </div>
          ) : !isLoading ? (
            <div className="empty-analysis">
              <h4 className="empty-analysis__title">No results!</h4>
            </div>
          ) : (
            <Loading size="large" />
          )}
        </div>
      ) : (
        permissions !== undefined && (
          <p className="my-[50%] mx-auto text-infrared-key">
            You don't have permission to add new analysis.
          </p>
        )
      )}
    </div>
  );
};

export default List;

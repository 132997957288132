export const transformedFilterItems = (filters) => {
  const transformedFilters = {
    pairs: {},
    dates: {},
    analytics: {},
    analyzers: {},
    timeframes: {},
  };
  if (filters) {
    // Transform pairs
    filters?.pairs.forEach((pair, index) => {
      transformedFilters.pairs[index] = pair.key;
    });

    // Transform dates
    if (filters?.date?.from)
      transformedFilters.dates.start = `${filters?.date.from?.year}/${
        filters?.date.from?.month?.number < 10
          ? `0${filters?.date.from?.month?.number}`
          : filters?.date.from?.month?.number
      }/${filters?.date.from?.day}`;
    if (filters?.date?.to)
      transformedFilters.dates.end = `${filters?.date.to?.year}/${
        filters?.date.to?.month?.number < 10
          ? `0${filters?.date.to?.month?.number}`
          : filters?.date.to?.month?.number
      }/${filters?.date.to?.day}`;

    // Transform analyzers
    filters?.analyzers?.forEach((analyzer, index) => {
      transformedFilters.analyzers[index] = analyzer.key;
    });
    // Transform analytics
    filters?.type?.forEach((type, index) => {
      transformedFilters.analytics[index] = type.key;
    });
    // Transform timeframes
    filters?.timeFrame?.forEach((timeFrame, index) => {
      transformedFilters.timeframes[index] = timeFrame.key;
    });
  }
  return transformedFilters;
};

export const transformedNotificationPoints = (notificationPoints) => {
  const transformedPoints = {
    points: {},
  };

  let index = 0;

  if (notificationPoints.entryPrice.price_range !== "") {
    transformedPoints.points[index] = {
      point_type: "ep",
      amount: notificationPoints.entryPrice.price_range,
      is_goes_over: notificationPoints.entryPrice.condition === "goes over" || false,
      rr: null,
    };
    index++;
  }

  if (notificationPoints.stopLoss.price_range !== "") {
    transformedPoints.points[index] = {
      point_type: "sl",
      amount: notificationPoints.stopLoss.price_range,
      is_goes_over: notificationPoints.stopLoss.condition === "goes over" || false,
      rr: null,
    };
    index++;
  }

  notificationPoints.takeProfits.forEach((item) => {
    if (item.price_range !== "") {
      transformedPoints.points[index] = {
        point_type: "tp",
        amount: item.value,
        is_goes_over: item.condition === "goes over" || false,
        rr: item.rr !== null ? Number(item.rr) : null,
      };
      index++;
    }
  });
  return transformedPoints;
};
export const transformedAnalysisImages = (formData, images, title) => {
  return images.forEach((item, index) => {
    if (item.file instanceof File) {
      formData.append(`${title[0]}[${index}][${title[1]}]`, item.file);
      formData.append(`${title[0]}[${index}][is_primary]`, item.active);
    } else {
      formData.append(`old_pictures[${index}][id]`, item.id);
      formData.append(`old_pictures[${index}][picture]`, item.src.split("/").pop());
      formData.append(`old_pictures[${index}][is_primary]`, item.active);
    }
  });
};
export const transformedAnalysisData = (
  formData,
  analysisData,
  type,
  pair,
  positionType,
  enableNotification,
  userId
) => {
  formData.append("pair_key", pair);
  formData.append("analysis_type", type);
  formData.append("description", analysisData?.description);
  formData.append("chart_data", JSON.stringify(analysisData.chart));
  if (positionType !== "-") formData.append("position_type", positionType);

  if (type === "technical") {
    let index = 0;

    for (const pointType of Object.keys(analysisData.points)) {
      for (const point of analysisData.points[pointType]) {
        if (point.value) {
          formData.append(`technical[${index}][point_type]`, pointType);
          formData.append(`technical[${index}][base_amount]`, point.value.split("-")[0]);
          if (point.value.split("-")[1])
            formData.append(`technical[${index}][max_amount]`, point.value.split("-")[1]);
          formData.append(`technical[${index}][point_description]`, point.desc);
          if (point.rr) formData.append(`technical[${index}][rr]`, point.rr);
          index++;
        }
      }
    }
    formData.append("time_frame_id", analysisData.timeFrame.key);
    formData.append("technical_type", analysisData.type.toLowerCase());
    if (enableNotification) {
      formData.append("notification_users[]", userId);
      for (const user of analysisData.notificationUsers)
        formData.append("notification_users[]", user.key);
    }
  } else if (type === "fundamental")
    if (analysisData.entries.length > 0)
      analysisData.entries.forEach((item, index) => {
        formData.append(`fundamental[${index}][analysis_date]`, item.date);
        formData.append(`fundamental[${index}][title]`, item.title);
        formData.append(`fundamental[${index}][analysis_description]`, item.tooltip);
      });
  transformedAnalysisImages(formData, analysisData.images, ["pictures", "file"]);
};

export const transformedNewHeatMapParams = (params) => {
  const transformedParams = {
    title: "",
    analytics: {},
    dates: {
      start: "",
      end: "",
    },
    description: "",
  };

  transformedParams.title = params.title;
  transformedParams.description = params.description;

  params.analysisType.forEach((item, index) => {
    transformedParams.analytics[index] = item.key;
  });
  transformedParams.dates.start = `${params?.date?.from?.year}-${params?.date?.from?.month?.number}-${params?.date?.from?.day}`;

  transformedParams.dates.end = `${params?.date?.to?.year}-${params?.date?.to?.month?.number}-${params?.date?.to?.day}`;

  return transformedParams;
};

export function transformedDetailsHeatMapParams(obj) {
  const params = new URLSearchParams();

  function flattenObject(data, prefix = "") {
    for (const [key, value] of Object.entries(data)) {
      const newPrefix = prefix ? `${prefix}[${key}]` : key;

      if (typeof value === "object" && value !== null) {
        flattenObject(value, newPrefix);
      } else {
        params.set(newPrefix, value);
      }
    }
  }

  flattenObject(obj);
  return params.toString();
}

import React, { useRef, useState, useContext } from "react";

import { notificationApi } from "~/api/dashboard/notificationApi";

import DatePicker from "~/components/child/ui/datePicker/DatePicker";
import Dropdown from "~/components/child/ui/DropDown";
import InputBase from "~/components/child/ui/InputBase";
import Button from "~/components/child/ui/Button";
import Symbols from "~/components/child/Symbols";
import { UserContext } from "~/components/child/UserContext";

import { useUsersList, useKeySubmission } from "~/utils/hooks";
import { transformedNotificationPoints } from "~/utils/getTransformedData";
import { riskToRewardResult } from "~/utils/riskToRewardResult";
import { onChangeCheckboxes } from "~/utils/onChangeCheckboxes";
import { changeWordFormatToTitleCase } from "~/utils/changeWordFormatToTitleCase";

import CopySvg from "~/assets/icon/svgJsx/CopySvg";

const CreateNew = ({
  setPopUpIsOpen,
  type,
  id,
  pair,
  heatMapPositionType,
  onSuccess,
}) => {
  const { user } = useContext(UserContext);

  const [notificationValues, setNotificationValues] = useState({
    pair: pair || {
      pair: "BTC-USDT",
      key: "BTCUSDT",
      image: [
        "https://s2.coinmarketcap.com/static/img/coins/64x64/1.png",
        "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png",
      ],
    },
    date: undefined,
    positionType: heatMapPositionType || "Long",
    points: {
      ep: { value: "", condition: "goes over" },
      sl: { value: "", condition: "goes over" },
      tp: [{ id: 0, value: "", rr: "", condition: "goes over" }],
    },
    users: [],
    description: "",
  });
  const [errorMessage, setErrorMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const analyzersList = useUsersList(true)?.data;
  const pointsPricesRegex = /^(\d{0,8}(\.\d{0,8})?)?$/;

  const textarea = useRef();
  const onSubmit = () => {
    setIsLoading(true);

    notificationApi
      .createNew(
        {
          pair_key: notificationValues.pair.key,
          validation_date: `${notificationValues.date?.month.number}/${notificationValues.date?.day}/${notificationValues.date?.year}`,
          position: notificationValues.positionType.toLowerCase(),
          points: transformedNotificationPoints({
            entryPrice: {
              price_range: notificationValues.points.ep.value.trim(),
              condition: notificationValues.points.ep.condition,
            },

            stopLoss: {
              price_range: notificationValues.points.sl.value.trim(),
              condition: notificationValues.points.sl.condition,
            },

            takeProfits: notificationValues.points.tp.filter(
              (item) => item?.value.trim() !== ""
            ),
          }).points,
          description: notificationValues.description,
          users:
            notificationValues.users.length > 0
              ? notificationValues.users.map((item) => item.key)
              : null,
        },
        type,
        id
      )
      .then((res) => {
        if (res && !res.error && res.data) {
          setPopUpIsOpen(false);
          if (onSuccess) onSuccess();
        } else if (res?.error) {
          if (res?.error.response?.data.errors) {
            setErrorMessage(res?.error.response?.data.errors);
          } else setErrorMessage([res?.error.response?.data.message]);
        }
      })
      .then(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      });
  };

  useKeySubmission(
    onSubmit,
    () =>
      isLoading ||
      (Number(notificationValues.points.ep.value) === 0 &&
        Number(notificationValues.points.sl.value) === 0 &&
        notificationValues.points.tp
          .map((item) => item.value !== "")
          .find((item) => item === true) === undefined) ||
      !notificationValues.date,
    (event) => event.key === "Enter",
    []
  );

  return (
    <div className=" py-3 px-6 max-h-[40rem] overflow-y-auto flex flex-col gap-2.5 scrollbar-style-thumb scrollbar-style">
      <h5 className="text-base text-sky-80">Create New notification</h5>

      <div className="flex flex-col gap-2 h-full">
        <Symbols
          editable={type === "heatmap" ? false : true}
          value={{
            key: notificationValues.pair.key,
            image: notificationValues.pair.image,
            value: notificationValues.pair.pair,
          }}
          onSelect={(item) =>
            setNotificationValues({
              ...notificationValues,
              pair: {
                key: item.key,
                image: item.image,
                pair: item.value,
              },
            })
          }
        />

        <DatePicker
          className="mb-1"
          inputLabel="Validation Date"
          emitDatePicker={(value) => {
            setNotificationValues({
              ...notificationValues,
              date: value,
            });
          }}
          forcible={true}
        />
        {(type !== "heatmap" || heatMapPositionType) && (
          <Dropdown
            editable={type === "heatmap" ? false : true}
            title="Position Type"
            value={{ value: notificationValues.positionType }}
            optionsList={["Long", "Short"]?.map((positionType) => ({
              key: positionType.toLocaleLowerCase(),
              value: positionType,
            }))}
            renderItem={(item) => (
              <span className="py-3 pl-2 text-xs font-roboto w-full flex cursor-pointer">
                {changeWordFormatToTitleCase(item.value)}
              </span>
            )}
            onSelect={(item) =>
              setNotificationValues({
                ...notificationValues,
                positionType: item.value,
              })
            }
          />
        )}

        <div className="flex items-baseline gap-1">
          <InputBase className={{ root: "w-full" }}>
            <div className="flex items-center justify-between gap-1 py-2 pl-2 w-full">
              <span className=" font-robotoMed text-xs text-oil-60">EP</span>
              <input
                className="w-full text-right px-2 text-xs text-oil-dark leading-normal border-none focus:border-none focus-visible:border-none outline-none"
                value={notificationValues.points.ep.value}
                onChange={(e) => {
                  if (pointsPricesRegex.test(e.target.value) || e.target.value === "")
                    setNotificationValues({
                      ...notificationValues,
                      points: {
                        ...notificationValues.points,
                        ep: {
                          ...notificationValues.points.ep,
                          value: e.target.value,
                        },
                        tp: notificationValues.points.tp.map((item) => {
                          return {
                            ...item,
                            rr: riskToRewardResult(
                              Number(e.target.value),
                              Number(notificationValues.points.sl.value),
                              Number(item.value)
                            ),
                          };
                        }),
                      },
                    });
                }}
                placeholder="$0.00"
                type="text"
              />
            </div>
          </InputBase>

          <Dropdown
            className="w-[66%]"
            value={{ value: notificationValues.points.ep.condition }}
            optionsList={["goes over", "goes under"]?.map((condition) => ({
              key: condition,
              value: condition,
            }))}
            renderItem={(item) => (
              <span
                className={` ${
                  item.value === "goes over" ? " text-leaf-50" : "text-infrared-50"
                } py-2 pl-2 text-xs font-roboto w-full flex cursor-pointer`}
              >
                {item.value}
              </span>
            )}
            onSelect={(item) =>
              setNotificationValues({
                ...notificationValues,
                points: {
                  ...notificationValues.points,
                  ep: {
                    ...notificationValues.points.ep,
                    condition: item.key,
                  },
                },
              })
            }
          />
        </div>

        <div className="flex items-baseline gap-1 ">
          <InputBase className={{ root: "w-full" }}>
            <div className="flex items-center justify-between gap-1 py-2 pl-2 w-full">
              <span className=" font-robotoMed text-xs text-oil-60">SL</span>
              <input
                className="w-full text-right px-2 text-xs text-oil-dark leading-normal border-none focus:border-none focus-visible:border-none outline-none"
                value={notificationValues.points.sl.value}
                onChange={(e) => {
                  if (pointsPricesRegex.test(e.target.value) || e.target.value === "")
                    setNotificationValues({
                      ...notificationValues,
                      points: {
                        ...notificationValues.points,
                        sl: {
                          ...notificationValues.points.sl,
                          value: e.target.value,
                        },
                        tp: notificationValues.points.tp.map((item) => {
                          return {
                            ...item,
                            rr: riskToRewardResult(
                              Number(notificationValues.points.ep.value),
                              Number(e.target.value),
                              Number(item.value)
                            ),
                          };
                        }),
                      },
                    });
                }}
                placeholder="$0.00"
                type="text"
              />
            </div>
          </InputBase>
          <Dropdown
            className="w-[66%]"
            value={{ value: notificationValues.points.sl.condition }}
            optionsList={["goes over", "goes under"]?.map((condition) => ({
              key: condition,
              value: condition,
            }))}
            renderItem={(item) => (
              <span
                className={` ${
                  item.value === "goes over" ? " text-leaf-50" : "text-infrared-50"
                } py-2 pl-2 text-xs font-roboto w-full flex cursor-pointer`}
              >
                {item.value}
              </span>
            )}
            onSelect={(item) =>
              setNotificationValues({
                ...notificationValues,
                points: {
                  ...notificationValues.points,
                  sl: {
                    ...notificationValues.points.sl,
                    condition: item.key,
                  },
                },
              })
            }
          />
        </div>

        {notificationValues.points.tp?.map((tpItem, index) => (
          <div className="flex items-baseline gap-1">
            <InputBase className={{ root: "w-full" }}>
              <div className="flex items-center justify-between gap-1 py-[1px] pl-2 w-full">
                <span className=" font-robotoMed text-xs text-oil-60">
                  TP.{index + 1}
                </span>
                <input
                  className="w-full text-right px-2 text-xs text-oil-dark leading-normal border-none focus:border-none focus-visible:border-none outline-none"
                  value={tpItem.value}
                  onChange={(e) => {
                    if (pointsPricesRegex.test(e.target.value) || e.target.value === "")
                      setNotificationValues({
                        ...notificationValues,
                        points: {
                          ...notificationValues.points,
                          tp: notificationValues.points.tp?.map((item) => {
                            if (item.id === tpItem.id)
                              return {
                                ...item,
                                value: e.target.value,
                                rr: riskToRewardResult(
                                  Number(notificationValues.points.ep.value),
                                  Number(notificationValues.points.sl.value),
                                  Number(e.target.value)
                                ),
                              };
                            return item;
                          }),
                        },
                      });
                  }}
                  placeholder="$0.00"
                  type="text"
                />
                <div className="btn-ocean-cstm">RR {tpItem.rr && tpItem.rr}</div>
              </div>
            </InputBase>

            <Dropdown
              className="w-[66%]"
              value={{ value: tpItem.condition }}
              optionsList={["goes over", "goes under"]?.map((condition) => ({
                key: condition,
                value: condition,
              }))}
              renderItem={(item) => (
                <span
                  className={` ${
                    item.value === "goes over" ? " text-leaf-50" : "text-infrared-50"
                  } py-2 pl-2 text-xs font-roboto w-full flex cursor-pointer`}
                >
                  {item.value}
                </span>
              )}
              onSelect={(conditionItem) =>
                setNotificationValues({
                  ...notificationValues,
                  points: {
                    ...notificationValues.points,
                    tp: notificationValues.points.tp?.map((item) => {
                      if (item.id === tpItem.id)
                        return {
                          ...tpItem,
                          condition: conditionItem.value,
                        };
                      return item;
                    }),
                  },
                })
              }
            />
          </div>
        ))}

        <button
          type="button"
          className=" w-fit text-sm font-roboto text-sky-key"
          onClick={() =>
            setNotificationValues({
              ...notificationValues,
              points: {
                ...notificationValues.points,
                tp: [
                  ...notificationValues.points.tp,
                  {
                    id: notificationValues.points.tp.length,
                    value: "",
                    rr: "",
                    condition: "goes over",
                  },
                ],
              },
            })
          }
        >
          + Add another TP
        </button>
        {user?.role[0]?.name !== "user" && (
          <Dropdown
            searchable
            bgColor="storm-10"
            placeholder={
              <span className=" font-robotoMed text-sm text-storm-80 py-1.5 pl-1.5">
                Send to other users
              </span>
            }
            value={
              notificationValues.users?.length > 0 ? notificationValues.users : undefined
            }
            shouldShowSelectedValues={false}
            multipleValues
            optionsList={analyzersList?.map((analyzer) => ({
              key: analyzer.uuid,
              value: analyzer.first_name + " " + analyzer.last_name,
            }))}
            renderItem={(item) => (
              <span className="py-2 pl-2 text-xs font-roboto w-full text-left">
                {item.value}
              </span>
            )}
            onSelect={(item) =>
              setNotificationValues({
                ...notificationValues,
                users: onChangeCheckboxes(notificationValues.users, {
                  key: item.key,
                  value: item.value,
                }),
              })
            }
          />
        )}

        <div className=" rounded-lg flex p-2 h-32 border border-storm-20 cursor-pointer font-roboto relative">
          <i>
            <CopySvg />
          </i>
          <textarea
            className="border-none outline-none resize-none w-full text-right font-yekanBakh text-sm  text-oil-60"
            onChange={(e) =>
              setNotificationValues({
                ...notificationValues,
                description: e.target.value,
              })
            }
            onKeyDown={(e) => {
              if (e.key === "Enter") e.stopPropagation();
            }}
            ref={textarea}
            name=""
            placeholder="write something"
            id=""
          ></textarea>
        </div>
        <div className="flex justify-between items-center gap-4 mt-2">
          <Button
            variant="outline"
            className="w-1/2"
            onClick={() => {
              setPopUpIsOpen(false);
            }}
          >
            Cancel
          </Button>

          <Button
            variant="primary"
            isLoading={isLoading}
            className="w-1/2"
            onClick={() => {
              onSubmit();
            }}
            disabled={
              isLoading ||
              (Number(notificationValues.points.ep.value) === 0 &&
                Number(notificationValues.points.sl.value) === 0 &&
                notificationValues.points.tp
                  .map((item) => item.value !== "")
                  .find((item) => item === true) === undefined) ||
              !notificationValues.date
            }
          >
            Add
          </Button>
        </div>
      </div>
      {errorMessage && Object.keys(errorMessage) && (
        <div className="notification__new-notification-popup__box__errorBox">
          {Object.keys(errorMessage)?.map((key) => (
            <p
              key={key}
              className="mt-1 font-roboto text-sm text-infrared-key text-center overflow-auto"
            >
              {errorMessage[key]}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default CreateNew;

export const normalizeData = (data) => {
  const values = data.map((row) => row.slice(1));

  const minValues = [];
  const maxValues = [];
  for (let col = 0; col < values[0].length; col++) {
    const column = values.map((row) => row[col]);
    minValues.push(Math.min(...column));
    maxValues.push(Math.max(...column));
  }

  const normalizedData = data.map((row, rowIndex) => {
    const timestamp = row[0];
    const normalizedRow = row.slice(1).map((value, colIndex) => {
      const min = minValues[colIndex];
      const max = maxValues[colIndex];
      return (value - min) / (max - min);
    });
    return [timestamp, ...normalizedRow];
  });

  return normalizedData;
};
